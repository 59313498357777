@font-face {
  font-family: "NanumSquareNeo";
  font-weight: 100;
  src: url(eot/NanumSquareNeoTTF-aLt.eot);
  src: url(woff/NanumSquareNeoTTF-aLt.woff) format("woff"),
    url(woff2/NanumSquareNeoTTF-aLt.woff2) format("woff2"),
    url(ttf/NanumSquareNeoTTF-aLt.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareNeo";
  font-weight: 300;
  src: url(eot/NanumSquareNeoTTF-bRg.eot);
  src: url(woff/NanumSquareNeoTTF-bRg.woff) format("woff"),
    url(woff2/NanumSquareNeoTTF-bRg.woff2) format("woff2"),
    url(ttf/NanumSquareNeoTTF-bRg.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareNeo";
  font-weight: 500;
  src: url(eot/NanumSquareNeoTTF-cBd.eot);
  src: url(woff/NanumSquareNeoTTF-cBd.woff) format("woff"),
    url(woff2/NanumSquareNeoTTF-cBd.woff2) format("woff2"),
    url(ttf/NanumSquareNeoTTF-cBd.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareNeo";
  font-weight: 700;
  src: url(eot/NanumSquareNeoTTF-dEb.eot);
  src: url(woff/NanumSquareNeoTTF-dEb.woff) format("woff"),
    url(woff2/NanumSquareNeoTTF-dEb.woff2) format("woff2"),
    url(ttf/NanumSquareNeoTTF-dEb.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquareNeo";
  font-weight: 800;
  src: url(eot/NanumSquareNeoTTF-eHv.eot);
  src: url(woff/NanumSquareNeoTTF-eHv.woff) format("woff"),
    url(woff2/NanumSquareNeoTTF-eHv.woff2) format("woff2"),
    url(ttf/NanumSquareNeoTTF-eHv.ttf) format("truetype");
}
